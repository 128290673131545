export default [
  { text: 'Наименование', value: 'name' },
  { text: 'Шифр', value: 'project_code' },
  { text: 'Адрес', value: 'construction_address' },
  { text: 'Привязанные требования', value: 'eir_sets_count' },
  {
    text: 'Последнее изменение',
    value: 'updated_at',
  },
]
