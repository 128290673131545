<template>
  <div>
    <div class="d-flex align-center">
      <h1>Проекты</h1>
      <v-btn
        v-if="isAdmin || isManager"
        class="ml-auto"
        large
        color="primary"
        elevation="10"
        @click="$refs.modal.show()"
      >
        <v-icon class="mr-1" size="18">mdi-plus</v-icon>
        Новый проект
      </v-btn>
    </div>

    <v-data-table
      class="custom-table"
      loading-text="Загрузка... Пожалуйста, подождите"
      :loading="isLoading"
      :headers="TABLE_HEADERS"
      :items="projects"
      :items-per-page="20"
      :hide-default-footer="projectsTotal <= 20"
      :server-items-length="projectsTotal"
      :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
      :options.sync="tableOptions"
      :mobile-breakpoint="0"
      sort-desc
      @click:row="clickRow"
    >
      <template #item.name="{ item }">
        <span :title="item.name">
          {{ item.name | truncate(70) }}
        </span>
      </template>

      <template #item.updated_at="{ item }">
        {{ item.updated_at | formatDate }}
      </template>

      <template #item.eir_sets_count="{ item: { eir_sets: eirSets } }">
        <DropdownMenu :items="eirSets" link-path="eir-sets" target-blank />
      </template>
    </v-data-table>

    <ModalProject ref="modal" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { TABLE_HEADERS } from './constants'

import ModalProject from '@/components/ModalProject'
import DropdownMenu from '@/components/DropdownMenu'

export default {
  name: 'Projects',
  components: { ModalProject, DropdownMenu },
  data: () => ({
    isLoading: false,
    TABLE_HEADERS,
    tableOptions: {},
  }),
  computed: {
    ...mapState('projects', ['projects', 'projectsTotal']),
    ...mapGetters(['isAdmin', 'isManager']),
  },
  watch: {
    tableOptions() {
      this.fetchProjectsWrapper()
    },
  },
  methods: {
    ...mapActions('projects', ['fetchProjects', 'deleteProject']),
    async fetchProjectsWrapper() {
      this.isLoading = true

      await this.fetchProjects({
        options: this.tableOptions,
      })

      this.isLoading = false
    },
    clickRow(row) {
      this.$router.push(`/projects/${row.id}`)
    },
  },
}
</script>
